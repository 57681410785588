
// /* Hide all content while the icons and typefaces are loading */
// html.fa-events-icons-loading body,
// html.fa-events-typefaces-lato-loading body,
// html.fa-events-typefaces-orbitron-loading body {
//   display: none;
// }

$enable-responsive-font-sizes: true;

$scot-gov-blue: #0065bd;
$theme-colors: (
  "primary": $scot-gov-blue,
  "danger": #E03997,
  "dark": #343a40,
  "light": #f8f9fa,
  "secondary": #6c757d
  );

// bootstrap and its default variables
@import "~bootstrap/scss/bootstrap";

// Alternate suggestion, but navbar doesn't work?
// @import "~bootstrap/scss/_functions";
// @import "~bootstrap/scss/_variables";
// @import "~bootstrap/scss/_mixins";
// // Optional
// @import "~bootstrap/scss/_reboot";
// @import "~bootstrap/scss/_type";
// @import "~bootstrap/scss/_images";
// @import "~bootstrap/scss/_code";
// @import "~bootstrap/scss/_grid";

$spot-muted: rgb(114, 114, 114);
$header-font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
$body-font-family: Hind Madurai, "Helvetica Neue", Helvetica, Arial, sans-serif;

body {
  font-family: $body-font-family;
  
  // fix ie 11 flexbox bug by adding display:flex to parent
  display: flex;
  flex-direction: column;

  // don't allow horizontal scrollbar
  // needed for the hidden panels on the map which move off to the
  // left and right - on Windows this causes a horizontal scrollbar
  overflow-x: hidden;
}

#layout-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

// z-index that is above the leaflet map (*and* its custom controls),
// but below the react-bootstrap modal!
@mixin above-map {
  z-index: 1001;
}

@mixin accessibility-border {
  outline: none;
  box-shadow: none;
  border-radius: 4px;
  border: 2px solid transparent;
  &:focus {
    box-shadow: none;
    border-color: black;
  }
}

h1 {
    color: $scot-gov-blue;
    font-family: $header-font-family;
}

a {
  text-decoration: none;
  color: $scot-gov-blue;
  &:focus, &:hover {
    text-decoration: underline;
    &.btn, &.nav-link {
      text-decoration: none;
    }
  }
}

.btn {
  @include accessibility-border;

  &.btn-primary:focus, &.btn-secondary:focus, &.btn-dark:focus {
    background-color: #f8f9fa;
    color: black;
  }
}

.icon-button {
  @include accessibility-border;

  background: none;
  margin: 0px;
  padding: 0px;
}

@mixin white-background {
  background-color: white;
}

.accessibility {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.home-main {
  :before {
    content: "";
    animation: home-anim 20s cubic-bezier(0, 0.5, 0, 1) forwards;
    background:
      linear-gradient(to bottom,  rgba(0,0,0,0), rgba(0,0,0,.5)),
      url(assets/lidar-home-page-map.jpg) no-repeat bottom
      #888;
    background-size: cover;
    position: absolute;
      top: -4rem;
      right: 0;
      bottom: 0rem;
      left: 0;
    z-index: -1;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  // min-height: 56vh;
  font-size: 18px;
  // overflow: hidden;
  // position: relative;
  text-align: center;

  .strap {
    margin: 4rem 0;
  }
  .strap-text {
    color: #fff;
    background-color: rgba(0,0,0,.5);
    padding: 1rem 5rem;
    margin: 0 -100rem 2rem -100rem;
    .strap-subtext {
      text-transform: uppercase;
      font-size: 24px;
      font-weight: 100;
    }
  }
}

@keyframes home-anim {
  0% {
    transform: translateY(-4rem);
  }
  100% {
    transform: none;
  }
}

.pointable {
  cursor: pointer;
}

.form-select {
  @include accessibility-border;

  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='white' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E");
  background-color: $scot-gov-blue;
  color: white;
  border-radius: 18px;
  padding: 6px 20px;
  min-width: 15rem;

  &:focus {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='black' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E");
    background-color: #f8f9fa;
    color: black;
  }
}

.form-check-input:checked {
  background-color: $scot-gov-blue;
  border-color: $scot-gov-blue;
}

.header {
  @include white-background;
  @include above-map;
  border-top: 0.4rem solid $scot-gov-blue;
  .container {
    padding-top: 1rem;
    padding-bottom: 1rem;
    .portal-icon {
      color: $scot-gov-blue;
      width: 1.5rem;
    }
  }
  .title-text {
    color: $spot-muted;
    font-family: $header-font-family;
    font-size: 200%;
    font-weight: 100;
  }
  .navbar {
    font-size: 115%;
    .container {
      padding-top: 0;
      padding-bottom: 0;
    }
    .nav-item {
      & a.nav-link {
        margin-bottom: 0;
        border-bottom: 0.3rem solid transparent;
      }
      &.active a.nav-link {
        border-bottom: 0.3rem solid $scot-gov-blue;
      }
      & a.nav-link:focus {
        border-bottom: 0.3rem solid $scot-gov-blue;
      }
    }
  }
}

.announcement-banner {
  @include white-background;
  @include above-map;  
  opacity: 0.8;
}

.footer {
  background-color: $gray-700;
  color: white;
  padding: 3rem 0;
  a {
    color: white;
    &:visited { color: white; }
    &:hover { color: white; }
    &:active { color: white; }
  }
  ul {
    margin-left: 1.5rem;
    li i {
      font-size: 60%;
    }
  }
  .footer-title {
    min-height: 3.3rem;
    padding-top: 0.4rem;
    > span {
      font-family: $header-font-family;
      font-size: 160%;
      font-weight: 300;
    }
    .portal-icon {
      width: 1.2rem;
    }
  }
  
  .jncc-partnership {
    .jncc-logo {
      filter: brightness(100);
      height: 3.3rem;
    }
  }
}

.normal-page-container {
  margin-top: 1.5rem;
  margin-bottom: 4rem;
}

.jumbo {
  .jumbo-heading {
    font-family: $header-font-family;
    font-size: 200%;
    font-weight: 300;
    .jumbo-bold {
      font-weight: 300;
    }
  }
}

.list-screen {
  .filter-bar {
    font-size: 115%;
    .badge {
      font-weight: normal;
      font-size: 100%;
    }
  }
  .filter-bar-hr {
    margin-bottom: 1.7rem;
  }
  .list-item {
    .list-item-title {
      font-size: 140%;
      display: inline-flex; /* make element size relative to content */
      align-items: center
    }
  }
}

.moreable {
  a {
    @include accessibility-border;

    font-size: 0; /* link elements should have text, but we don't want it visible here */
    float: right;
  }
  a.collapsed:after {
    content: '+ Show More';
    font-size: initial;
  }
  a:not(.collapsed):after {
    content: '- Show Less';
    font-size: initial;
  }
  .moreable-content {
    margin-bottom: 0;

  }
  .moreable-content.collapse:not(.show) {
    display: block;
    height: 4.7rem;
    overflow: hidden;
    margin-bottom: 0;
  }

  .moreable-content.collapsing {
    height: 4.7rem;
  }  
}

.wms-modal {
  .modal-body {
    padding: 3rem;
  }
  .gutter-line {
    border-right: 1px solid $gray-300;
  }
  .wms-link {
    font-size: 90%;
  }
}

.popover {
  padding: .5rem 0.75rem;
}

.dataset-path {
  font-weight: bold;
  font-size: 115%;
  .dataset-path-segment {
    color: $gray-600;
  }
  .dataset-path-separator {
    padding: 0 0.15rem;
    color: $gray-600;
    font-weight: normal;
  }
}

#leaflet-map {
  width: 100%;
  height: 100%;
  position: absolute;
  left:0;
  top:0;
  right:0;
  bottom:0;

  // hack the styling for the leaflet-editable plugin
  .leaflet-div-icon {
    width: 20px !important;
    height: 20px !important;
    margin-left: -10px !important;
    margin-top: -10px !important;
  }
}

$panel-gutter: 1.5rem;

.r {
  position:relative;
}
.left-panel-container {
  position: absolute;
  top: $panel-gutter;
  left: $panel-gutter;
  @include above-map;
  max-width: 29rem;
}
.left-panel-container-toggle {
  position: absolute;
  right: -1rem;
  top: 12rem;
  background-color: white;
  height: 3rem;
  width: 1rem;
  border-radius: 0 0.3rem 0.3rem 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 10px 6px -6px #777;
}
.right-panel-container {
  position: absolute;
  top: $panel-gutter;
  right: $panel-gutter;
  @include above-map;
  width: 21rem;
}
.right-panel-container-toggle {
  position: absolute;
  left: -1rem;
  top: 12rem;
  background-color: white;
  height: 3rem;
  width: 1rem;
  border-radius: 0.3rem 0 0 0.3rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0 10px 6px -6px #777;
}
.bottom-left-controls {
  @include above-map;
  position: absolute;
  bottom: $panel-gutter;
  left: $panel-gutter;
  display: flex;
  flex-direction: row;
  .btn {
    padding: 0.45rem 0.7rem;;
  }
  >div {
    box-shadow: 0 10px 6px -6px #777;
  }
  i {
    color: $gray-700;
  }
  .zoom-icon {
    color: $gray-800;
  }
  .little-control-container {
    background-color: white;
    border-radius: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.8rem;
  }
}
.bottom-right-control-group {
  position: absolute;
  bottom: $panel-gutter;
  right: $panel-gutter;
}
.panel {
  @include above-map;
  background-color: white;
  padding: 1rem;
  box-shadow: 0 10px 6px -6px #777;
  margin-bottom: 1rem;
  border-radius: 0.3rem;
}
.scrollable-panel {
  overflow-y: scroll;
}

.hoverable-little-icon {
  cursor: pointer;
  i {
    color: $gray-600;
    &:hover {
      color: $gray-700;
    }
  }
}

.dataset-list-subheader {
  color: $gray-600;
}
.dataset-list-item {
  display: flex;
  :first-child {
    flex: 1;
  }
  .dataset-list-item-product-count {
    color: $gray-700;
  }
}

.product-list-panel {
  position: relative;
  display: flex;
  flex-direction: column;
  .product-list-panel-abstract {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    width: 19rem; // leave room for scrollbar on some platforms
  }
  .product-list-items {
    flex: 1;
    min-height:15.4rem;
  }
  .product-list-item {
    display: flex;
    .product-list-item-bullet {
      color: $gray-600;
      font-size: 80%;
    }
    .product-list-item-highlight {
      position: relative;
      left: -9px;
      width: 4px;
      margin-right: -4px;
      background-color: $gray-700;
    }
    .product-list-item-title {
      flex: 1;
      &.product-list-item-title-highlight {
        background-color: $gray-100;
      }
    }
    .product-list-item-basket {
      @include accessibility-border;
      cursor: pointer;
      i {
        color: $gray-300;
        &:hover {
          color: $gray-500;
        }
      }
      &.product-list-item-basket-in {
        i { color: $gray-600; }  
      }
    }
    i { color: $gray-600; }
  }
  .add-all-to-basket {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    >div {
      @include accessibility-border;

      padding-right: 0.15rem;
      >i {
        color: $gray-500;
        margin-left: 0.4rem;
      }
      &:hover {
        cursor: pointer;
        >i { color: $gray-600; }
      }
      &.add-all-to-basket-disabled {
        cursor: auto;
        color: $gray-300;
        >i { color: $gray-300; }
      }
    }
  }
}

.pager {
  display: flex;
  flex-direction: row;
  .pager-central {
    flex: 1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    white-space: nowrap;
    .pager-dash {
      margin: 0 0.1rem;
      font-size: 90%;
    }
    .pager-of {
      font-size: 90%;
    }
  }
}

.basket-summary {
  display: flex;
  align-items: center;
  padding: 0 -0.2rem;
  >* {
    padding:0 0.2rem;
  }
  i {
    color: $gray-600;
  }
  .basket-summary-stretchy {
    flex: 1;
  }
  .basket-summary-count {
    font-size: 150%;
  }
}

.download-screen {
  .download-item {
    &:hover {
      background-color: $gray-100;
    }
    .download-item-title {
      font-size: 110%;
      .download-item-bullet {
        color: $gray-600;
        font-size: 80%;        
      }
    }
    .download-item-name {
      color: $spot-muted;
      font-weight: bold;;      
    }
    .download-item-type {
      color: $spot-muted;
      font-weight: bold;;      
    }
    .download-item-indicator {
      i { color: $gray-600; }
    }
  }
}

.case-study-paragraph-with-image {
  display: flex;
  align-items: flex-start;
  img {
    height:400px;
    float: right;
    padding-left: 10px;
    padding-right: 10px;
  }
  .case-study-image-2 {float: left}
  .case-study-image-3 {height:500px}
}

// CIVIC COOKIE CONTROL CUSTOMISATIONS
//
// make hyperlinks look different to normal text
.civic-cookie-control-link {
  text-decoration: underline;
  &:visited { text-decoration: underline; }
  &:hover { text-decoration: underline; }
  &:active { text-decoration: underline; }
}
.civic-cookie-banner-title {
  font-size: 2em;
  margin: 3em 0;
}
.ccc-notify-text {
  margin-top: 7em;
  margin-bottom: 7em;
  padding-right: 2em;
}
.cc-panel {
  padding: 1em;
}
// hack to hide the "Accept" button - the cookie control UI is otherwise very misleading
#ccc-button-holder {
  display: none;
}
//
// END CIVIC COOKIE CONTROL CUSTOMISATIONS
